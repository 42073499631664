/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import Flame from 'img/hover-menu.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};

  & a {
    color: ${(props) => props.theme.color.light};
  }

  & h2 {
    font-size: 16px;
  }

  & ul {
    margin-top: 1rem;
    & li {
      margin-bottom: 0.75rem;
    }
  }

  & a:hover {
    text-decoration: underline;
  }
`

const FlameWrapper = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
`

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            title
            menu {
              link {
                title
                url
                target
              }
            }
          }
          infoFooter {
            title
            columns {
              description
            }
            cta {
              title
              url
            }
            contactinfo {
              phone {
                title
                url
              }
              email {
                title
                url
              }
              coc
            }
          }
        }
      }
    }
  `)

  const { menuFooter }: any = fields?.footer
  const { infoFooter }: any = fields?.footer

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              {menuFooter.map(({ title }: any, index: number) => (
                <div className="col-6" key={index}>
                  <FlameWrapper className="d-flex">
                    <Flame />
                    <h2 className="ms-2">{title}</h2>
                  </FlameWrapper>
                  <ul>
                    {menuFooter![index]?.menu?.map(
                      ({ link }: any, linkIndex: number) => (
                        <li key={linkIndex}>
                          {link?.target === '_blank' ? (
                            <a
                              href={link?.url || '/'}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link?.title}
                            </a>
                          ) : (
                            <NavLink to={link?.url || '/'}>
                              <ParseContent content={link.title} />
                            </NavLink>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <FlameWrapper className="d-flex align-items-center">
              <Flame />
              <h2 className="ms-2 mb-0">{infoFooter.title}</h2>
            </FlameWrapper>
            <div className="ps-4 mt-3">
              <div className="row">
                {infoFooter.columns.map((column: any, index: number) => (
                  <div className="col-lg-4" key={index}>
                    <ParseContent content={column.description} />
                  </div>
                ))}
              </div>
              <div className="row mt-5">
                <div className="col-lg-5">
                  <ButtonPrimary to={infoFooter.cta.url} className="mb-4">
                    {infoFooter.cta.title}
                  </ButtonPrimary>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col-1">
                      <span>T</span>
                    </div>
                    <div className="col-11">
                      <a href={infoFooter.contactinfo.phone.url}>
                        {infoFooter.contactinfo.phone.title}
                      </a>
                    </div>
                    <div className="col-1">
                      <span>E</span>
                    </div>
                    <div className="col-11">
                      <a href={infoFooter.contactinfo.email.url}>
                        {infoFooter.contactinfo.email.title}
                      </a>
                    </div>
                    <div className="col-1">
                      <span>Kvk</span>
                    </div>
                    <div className="col-11">
                      <span>{infoFooter.contactinfo.coc}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
